import type { MachineryAccessoryCategory } from '~/types'

const brandingIcons: Record<MachineryAccessoryCategory, string> = {
  hookShort: 'accessory:hook-short',
  hookLong: 'accessory:hook-long',
  clipOnHook: 'accessory:clip-on-hook',
  latticeMastWinch: 'accessory:lattice-mast-winch',
  winch: 'accessory:winch',
  wheels: 'accessory:wheels',
  workBasket: 'accessory:work-basket',
  clipOnBasket: 'accessory:clip-on-basket',
  charger: 'accessory:charger',
  weights: 'accessory:weights',
  rollers: 'accessory:miscellaneous',
  stands: 'accessory:stands',
  forkAdjuster: 'accessory:fork-adjuster',
  sideShifter: 'accessory:side-shifter',
  forkCarriage: 'accessory:fork-carriage',
  ramps: 'accessory:ramps',
  jib: 'accessory:crane-arm',
  heavyCasters: 'accessory:heavy-casters',
  shovel: 'accessory:shovel',
  loadDistributionPlates: 'accessory:load-distribution-plates',
  ballast: 'accessory:ballast',
  forkExtension: 'accessory:fork-extension',
  fork: 'accessory:fork',
  craneArm: 'accessory:crane-arm',
  rotaryDevice: 'accessory:rotary-device',
  coilDorn: 'accessory:coil-dorn',
  abusBogie: 'accessory:abus-bogie',
  miscellaneous: 'accessory:miscellaneous',
  heavyDutyLifter: 'accessory:heavy-duty-lifter',
  basePlate: 'accessory:base-plate',
  trailer: 'accessory:trailer',
  storageSpace: 'accessory:miscellaneous',
  internalOperatingEquipment: 'accessory:miscellaneous',
}
export default () => brandingIcons
